div.album-container {
    display: inline-block;
    padding: 24px 16px;
    transition: all 0.2s ease-out;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    width: fit-content;
    min-width: 250px;
    margin: 8px;
    background: rgba(255, 255, 255, 0.075);
}

div.album-container:hover {
    background: rgba(235, 211, 102, 0.25);
}

div.album-container div.album-img-container {
    width: fit-content;
}

div.album-container div.album-cover a {
    display: block;
    margin: 4px auto 16px auto;
    width: fit-content;
    transition: all 0.2s ease-in-out;
}

div.album-container div.album-cover a:hover {
    transform: scale(1.1);
}

div.album-container div.album-cover a img.album-cover-img {
    border-radius: 5px;
}

div.album-container a.album-img {
    display: inline-block;
}

div.album-container a.album-img img {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    display: block;
    transition: all 0.2s ease-out;
}

div.album-container a.album-img:hover {
    transform: scale(1.1);
}

div.album-container h5.album-title {
    margin: 0 0 8px 0;
}

div.album-container h5.album-title a {
    font-weight: 500;
} 

p.album-contents, p.album-created {
    margin: 6px 0;
    color: #bdbdbd;
    font-size: 14px;
}
 
p.album-contents::before, p.album-created::before {
    font-family: 'Material Icons';
    font-size: 20px;
    color: #eee;
    margin-right: 8px;
    position: relative;
    top: 4px;
}

div.album-container p.album-contents {
    padding-bottom: 16px;
    border-bottom: solid 1px #bdbdbd;
    margin-bottom: 8px;
}

p.album-contents::before {
    content: 'photo_library';
}

p.album-created::before {
    content: 'schedule';
}