/* --- General Image Styles --- */

img.site-logo {
    max-height: 60px;
    max-width: auto;
}

/* Images Page */
div.wall-imgs-container {
    width: fit-content;
    display: inline-block;
    margin: 8px;
    position: relative;
}

div.wall-imgs-container a, div.wall-imgs-container a img, div.album-single-imgs-container a.album-single-img img  {
    display: inline-block;
    transition: all 0.2s ease-in-out;
}

div.wall-imgs-container a img.wall-img, div.album-single-imgs-container a.album-single-img img {
    border-radius: 5px;
    height: 120px;
    width: 120px;
    object-fit: cover;
    object-position: center;
}

div.single-img-container {
    margin: 24px auto;
    width: fit-content;
}

div.single-img-container a {
    transition: all 0.2s ease-out;
    display: block;
    text-decoration: none;
    position: relative;
}

div.single-img-container a img.single-img {
    border-radius: 10px;
    transition: all 0.2s ease-out;
    width: 100%;
    height: auto;
    max-height: 500px;
    position: relative;
}

div.single-img-container a:hover img.single-img, div.wall-imgs-container a:hover img.wall-img {
    transform: scale(1.1);
}

div.single-img-container a::before {
    transform: scale(1.1);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.25);
    z-index: 15;
    border-radius: 10px;
    opacity: 0;
}

div.single-img-container a:hover::before {
    opacity: 1;
}

div.single-img-container a:hover::after {
    content: 'open_in_new';
    font-family: 'Material Icons';
    color: #fff;
    font-size: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
}
