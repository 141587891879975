div.modal {
    z-index: 25000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    display: none;
    transition: all 500ms ease-in;
}

div.modal.open {
    display: block;
}

div.modal div.modal-content {
    position: fixed;
    z-index: 30000;
    border-radius: 10px;
    background: #333;    
    padding: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 600px;
    max-height: 80vh;
    overflow-x: hidden;
    transition: all 500ms ease-in;
}



button.modal-close.default-close {
    background: var(--dark-background);
    position: sticky;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #bdbdbd;
    outline: none;
    border: none;
    transition: all 0.2s ease-in;
    float: right;
    padding: 8px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.modal-close:hover.default-close {
    transform: scale(1.1);
}

div.modal-footer {
    position: sticky;
    bottom: -17px;
    padding-top: 8px;
    padding-bottom: 16px;
    background: #333;
    right: 0;
    left: 0;
    box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
}

div.modal-footer *:first-child {
    width: 100%
}