form#formAlbumSettings {
    width: 100%;
    padding: 0;
    margin: 0;
}

form#formAlbumSettings p[data-column]::before {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 16px;
    content: attr(data-column);
    background: var(--primary-color);
    font-size: 24px;
    color: #fff;
    height: 36px;
    width: 36px;
    font-family: 'Material Icons';
}

div.album-details {
    background: rgba(255, 255, 255, 0.075);
    padding: 24px;
    border-radius: 0 0 5px 5px;
}

div.album-details p.album-created, div.album-details p.album-created {
    font-size: 16px;
}

div.album-details p span.copy-to-clipboard {
    background: var(--dark-background);
    padding: 4px;
    border-radius: 2.5px;
    margin-top: 4px;
}

div.album-details p.album-created::before, div.album-details p.album-created::before {
    font-size: 24px;
}

div.album-single-imgs-container {
    margin: 24px 0;
    display: inline-block;
}

.album-single-imgs-container a.album-single-img {
    text-decoration: none;
    transition: all 0.2s ease-in;
    margin: 8px;    
    display: inline-block;
}

.album-single-imgs-container a.album-single-img:hover {
    transform: scale(1.1);
}

div.album-details div.album-cover-container {
    padding: 4px;
    border-radius: 5px;
    border: solid 4px var(--primary-color);
    margin-right: 24px;
    width: fit-content;
    background: var(--dark-background);
}

div.album-details div.album-cover-container a.album-single-cover-img {
    display: block;
    transition: all 0.2s ease-in;
}

div.album-details div.album-cover-container a.album-single-cover-img:hover {
    transform: scale(1.1);
}

div.album-details div.album-cover-container a.album-single-cover-img img {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    display: block;
}

div.album-details div.album-extended-info {
    display: flex; 
    justify-content: flex-start;
    align-items: flex-start;
}

div.album-details div.album-stats h5 {
    margin: 0 0 8px 0;
}

div.album-details div.album-stats p {
    margin: 16px 0;
}

div.album-details div.album-cover-info p {
    margin: 0;
    background: var(--dark-background);
    text-align: center;
    border-radius: 5px;
    padding: 4px;
    width: 140px;
}

