footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 24px 0;
}

footer p {
    border-top: solid 1px rgba(255,255,255,0.25);
    padding-top: 16px;
    font-size: 12px;
    width: 100%;
    text-align: center;
}