/* Contains Styling for Mobile Responsiveness */
/* 
    BREAKPOINTS
    --break-sm: 500;
    --break-md: 800px;
    --break-lg: 992px;
    --break-xl: 1200px;
*/

@media (max-width: 500) {
    /* Header, Nav & General */

    main {
        max-width: 100%;
        margin-left: 16px;
        margin-right: 16px;
    }

    div.message-notice {
        bottom: 8px;
        left: 8px;
        right: 8px;
    }

    .copy-to-clipboard-prompt {
        left: 8px;
        right: 8px;
        bottom: 8px;
        margin: 0;
    }

    /* Modals */
    div.modal div.modal-content {
        width: calc(100vw - 32px);
        top: 16px;
        left: 16px;
        right: 16px;
        transform: translate(0, 0);
    }

    /* /upload */
    div.upload-album-options {
        display: block;
    }

    div.upload-album-options p {
        margin: 16px 0;
    }
}

@media (max-width: 800px) {
    /* Header */
    header {
        padding-bottom: 8px;
        padding-top: 8px;
    }

    header.anonymous-user {
        display: block;
    }

    header.logged-in-user {
        display: flex;
        justify-content: space-between;
    }

    header.anonymous-user a.home-link {
        border-bottom: solid 1px rgba(255,255,255,0.25);
        width: fit-content;
        margin: 0 auto 8px auto;
    }

    header a.home-link img {
        max-height: 48px;
    }

    header a.home-link h2 {
        font-size: 24px;
    }

    header.logged-in-user nav a:not(.nav-menu-container a) {
        display: none;
    }

    /* /user */
    div.user-info div.user-info-extended {
        display: block;
    }

    div.user-info div.user-info-extended .user-stats {
        margin-top: 16px;
    }

    /* /album_view */
    div.album-details div.album-extended-info {
        display: block;
    }

    div.album-details div.album-extended-info .album-stats {
        margin-top: 16px; 
    }
}