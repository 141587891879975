/* This File Contains Global CSS Values */

:root {
    --header-background: #000;
    --dark-background: #212121;
    --primary-color: #EBD366;
    --primary-hover: #e5c639;
    --font-family: 'Montserrat', sans-serif;
    --success-color: #69f0ae;
    --error-color: #ff5252;
}