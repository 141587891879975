* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background: var(--dark-background);
}

main {
    margin: 0 auto;
    max-width: 85%;
}

.notice {
    background: rgba(255, 255, 255, 0.075);
    padding: 24px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
}

.notice img.site-logo {
    margin-right: 8px;
}

.notice p {
    color: #bdbdbd;
}

div.message-notice {
    padding: 16px 24px;
    position: fixed;
    bottom: 24px;
    left: 24px;
    text-align: center;
    background: var(--primary-color);
    width: fit-content;
    border-radius: 5px;
    z-index: 100000;
}

div.message-notice p::before {
    display: inline-block;
    content: 'info';
    font-family: 'Material Icons';
    color: #212121;
    font-size: 24px;
    margin-right: 8px;
    position: relative;
    top: 6px;
}

div.message-notice p {
    color: #212121;
    font-weight: 500;
    z-index: 30000;
    margin: 0;
}

.copy-to-clipboard-prompt {
    position: fixed;
    width: fit-content;
    left: 24px;
    bottom: 24px;
    padding: 16px 24px;
    background: var(--primary-color);
    transition: all 0.2s ease-in-out;
    color: #212121;
    border-radius: 5px;
    z-index: 30000;
}   

.copy-to-clipboard-prompt::before {
    content: 'content_copy';
    font-family: 'Material Icons';
    font-size: 18px;
    position: relative;
    top: 4px;
    color: #212121;
    margin-right: 8px;
}

hr {
    border: none;
    border-top: solid 1px rgba(255,255,255,0.25);
}

span.stat-detail {
    color: #bdbdbd;
    margin: 4px 0;
    padding: 4px;
    background: var(--dark-background);
    border-radius: 5px;
    display: block;
    width: fit-content;
}

span.stat-detail:hover {
    color: #fff;
}

.modal .modal-content span.stat-detail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
    height: 36px;
    width: 100%;
}

.modal .modal-content span.stat-detail:hover {
    height: 110px;
    white-space: normal; 
    text-overflow: initial;
    overflow-y: scroll;
}

.center {
    margin-right: auto;
    margin-left: auto;
}

hr.paginate-hr {
    border-top-style: dashed;
    width: 100%;
    flex-grow: 1;
}

.pagination {
    justify-content: center;
    align-items: center;
    display: flex;
}
