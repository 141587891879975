/* --- General Header --- */

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background: var(--header-background);
    border-bottom: solid 1px rgba(255,255,255,0.25);
    margin-bottom: 8px;
}

header a.home-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

header h2.site-logo-text {
    color: #fafafa;
    font-family: monospace;
}

header h2.site-logo-text span {
    color: var(--primary-color);
    text-transform: uppercase;
}


header nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

header nav a:not(.nav-menu a) {
    text-decoration: none;
    margin: 8px;
    padding: 4px 16px;
    border-radius: 5px;
    border: solid 2px var(--header-background);
}

header nav a:not(.nav-menu a):hover {
    color: var(--primary-color);
    border: solid 2px var(--primary-color);
}

header nav a:first-child:not(.nav-menu a) {
    background: var(--primary-color);
    color: #212121;
}

header nav a:first-child:not(.nav-menu a):hover {
    background: var(--primary-hover);
    color: #212121;
}

/* --- Dropdown Nav --- */

div.nav-menu-container {
    position: relative;
}

div.nav-menu-container .nav-menu {
    width: fit-content;
    background: #424242;
    position: absolute;
    top: 24px;
    right: 0;
    z-index: 10000;
    padding: 24px;
    border-radius: 5px 0 5px 5px;
    transition: all 0.75 ease-in;
    display: none;
}

div.nav-menu-container .nav-menu li {
    padding: 8px 16px;
    border-radius: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.nav-menu-container .nav-menu li i.material-icons {
    margin-right: 16px;
    color: #fff;
    font-size: 24px;
}

div.nav-menu-container .nav-menu li.nested-li {
    padding-left: 64px;
}


div.nav-menu-container .nav-menu li:hover {
    background: rgba(0,0,0,0.15);
}

div.nav-menu-container .nav-menu li:hover a {
    color: var(--primary-color);
}

div.nav-menu-container .nav-menu a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    transition: all 0.2s ease-in-out;
}

div.nav-menu-container .nav-menu li.nested-li a {
    font-size: 14px;
}

div.nav-menu-trigger {
    padding: 1px;
    border-radius: 100%;
    border: solid 3px var(--primary-color);
    margin-left: 16px;
}

#BurgerButton {
    background-position: center;
    background-size: cover;
    height: 56px;
    width: 56px;
    border-radius: 100%;
    background-repeat: no-repeat;
}   