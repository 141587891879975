/* --- General Form Styles --- */
form.dropzone {
    width: 100%;
    background: rgba(255, 255, 255, 0.075);
    padding: 24px;
    text-align: center;
    cursor: pointer;
    border: dashed 2.5px var(--primary-color);
    border-radius: 0 0 5px 5px;
}

form.dropzone h4 {
    color: var(--primary-color);
    margin: 0 auto;
}

form.dropzone h6 {
    margin: 8 auto;
}

form.dropzone p:not(.upload-album-options p) {
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

form.dropzone p.dz-upload-click::before {
    display: inline-block;
    color: var(--primary-color);
    font-size: 24px;
    content: 'ads_click';
    font-family: 'Material Icons';
    margin-right: 8px;
}

form.dropzone p.dz-upload-drag::before {
    display: inline-block;
    color: var(--primary-color);
    font-size: 24px;
    content: 'pan_tool_alt';
    font-family: 'Material Icons';
    margin-right: 8px;
    transform: rotate(-45deg);
}

form.dropzone p.dz-types {
    font-size: 14px;
    color: #bdbdbd;
    margin-top: 16px;
}

form.dropzone i.material-icons.upload-icon {
    color: var(--primary-color);
    font-size: 72px;
    margin: 8px 0;
}

/* --- Preview Image Styles --- */

div.dz-preview {
    border-radius: 10px;
    position: relative;
}

div.dz-preview div.dz-image {
    min-height: 120px;
}

div.dz-preview div.dz-image img {
    border-radius: 5px;
    border: solid 4px var(--primary-color);
}

/* --- Error Styles --- */

/* Hide Mark Whilst Loading */
div.dz-preview.dz-processing.dz-image-preview:not(.dz-complete) div.dz-error-mark, div.dz-preview.dz-processing.dz-image-preview:not(.dz-complete) div.dz-success-mark  {
    opacity: 0;
}

div.dz-preview.dz-success div.dz-error-message, div.dz-preview.dz-success div.dz-error-mark {
    display: none !important;
}

div.dz-preview.dz-error.dz-complete div.dz-image img {
    border-color: var(--error-color);
}

div.dz-preview.dz-error.dz-complete:hover .dz-error-message::before {
    content: 'ERROR UPLOADING - ';
    display: inline-block;
    color: var(--error-color);
}

div.dz-preview.dz-error.dz-complete:hover .dz-error-message {
    display: block;
    position: absolute;
    z-index: 10000;
    top: 128px;
    text-align: left;
    max-width: 500px;
    min-width: 150px;
    font-family: var(--font-family);
    color: #eee;
    font-size: 12px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    border-radius: 10px;
    background:rgba(0, 0, 0, 0.85);
}

div.dz-preview.dz-error.dz-complete .dz-error-message, div.dz-preview.dz-error.dz-processing:not(.dz-complete) .dz-error-mark {
    display: none;
}

div.dz-error-mark {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

div.dz-error-mark::after {
    background: rgba(0,0,0,0.45);
    border-radius: 100%;
    padding: 8px;
    content: 'info';
    display: block;
    font-family: 'Material Icons';
    color: var(--error-color);
    font-size: 36px;
}

div.dz-error-mark svg {
    display: none;
}

/* --- Success Styles --- */

div.dz-preview.dz-success div.dz-image img {
    border-color: var(--success-color);
}

div.dz-success-mark {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

div.dz-success-mark::after {
    background: rgba(0,0,0,0.45);
    border-radius: 100%;
    padding: 8px;
    content: 'check_circle';
    display: block;
    font-family: 'Material Icons';
    color: var(--success-color);
    font-size: 36px;
}

div.dz-success-mark svg {
    display: none;
}

/* --- Progress Bar --- */
.dz-preview.dz-complete .dz-progress  {
    display: none !important;
}

.dropzone .dz-preview .dz-progress span.dz-upload {
	background: #fff;
	border-radius: 5px;
	border: solid 4px var(--dark-background);
}

.dropzone .dz-preview div.dz-progress {
    border: none;
}


/* File Size & File Name */
.dz-details {
    margin: 8px 0;
}

.dz-filename span, .dz-size span {
    font-family: var(--font-family);
    font-weight: 400;
}

.dz-size span {
    color: #bdbdbd;
    font-size: 12px;
}

.dz-filename span {
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 120px;
}

.dz-size span strong {
    font-weight: 400;
}

div.upload-album-options {
    border-top: solid 1px rgba(255,255,255,0.25);
    margin-top: 16px;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.upload-album-options p {
    text-align: left;
    width: fit-content;
    margin: 0 8px;
}

div.album-options p select, div.album-options p input {
    min-width: 200px;
}