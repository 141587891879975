form:not(.dropzone) {
    border-radius: 5px;
    padding: 36px;
    width: fit-content;
    background: #333;
}

/* --- Input Styles --- */

form:not(.dropzone) input[type="text"], form:not(.dropzone) input[type="password"], form:not(.dropzone) textarea, form:not(.dropzone) input[type="email"], form:not(.dropzone) select, form.dropzone div.upload-album-options input, form.dropzone div.upload-album-options select {
    padding: 8px;
    background: rgba(255, 255, 255, 0.35);
    filter: brightness(150%);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    color: #fff;
    width: 100%;
}

form:not(.dropzone) input[type="text"]:focus, form:not(.dropzone) input[type="password"]:focus, form:not(.dropzone) textarea:focus, form:not(.dropzone) input[type="email"]:focus, form.dropzone div.upload-album-options input:focus {
    background: rgba(235, 211, 102, 0.45);
    filter: brightness(150%);
}

form:not(.dropzone) select, form.dropzone div.upload-album-options select {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    filter: brightness(1);
    min-width: 200px;
}

form:not(.dropzone) select > option {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
}

form:not(.dropzone) select:focus, form.dropzone div.upload-album-options select:focus {
    background: #9e9e9e;
}

form:not(.dropzone) textarea {
    min-width: 100%;
    width: 100%;
    max-height: 300px;
    min-height: 150px;
    max-width: 500px;
    transition: all 0.1s;
    resize: vertical;
}

/* --- Label & Validation Styles --- */

form:not(.dropzone) input[type="checkbox"] + label {
    display: inline-block;
    position: relative;
    bottom: 2px;
}

form:not(.dropzone) input + span, form:not(.dropzone) input + br + span {
    font-size: 14px;
}

form:not(.dropzone) input + br + span::before {
    content: 'arrow_upward';
    font-family: 'Material Icons';
    position: relative;
    top: 4px;
    font-size: 18px;
    margin-right: 4px;
}

form:not(.dropzone) input + span::before {
    content: 'arrow_back';
    font-family: 'Material Icons';
    position: relative;
    top: 4px;
    font-size: 18px;
    margin-right: 4px;
}

form:not(.dropzone) label, form.dropzone div.upload-album-options p label {
    margin-bottom: 4px;
    display: block;
    text-transform: capitalize;
}

form:not(.dropzone) label::after, form.dropzone div.upload-album-options p label::after {
    content: '*';
    display: inline-block;
    color: red;
    margin-left: 4px;
}

form:not(.dropzone) label + br, form.dropzone div.upload-album-options p label + br {
    display: none;
}

/* --- Checkbox & Radio Styles */

form:not(.dropzone) input[type="checkbox"], form:not(.dropzone) input[type="radio"] {
    height: 24px;
    width: 24px;
    cursor: pointer;   
}

form:not(.dropzone) input[type="checkbox"] + label, form:not(.dropzone) input[type="radio"] + label {
    display: inline-block;
    color: #fff;
    margin-left: 4px;
    line-height: 24px;
    bottom: 4px;
    position: relative;
}

form:not(.dropzone) input[type="checkbox"] + label::after {
    content: '?';
    color: #fff;
    margin-left: 0;
}

form:not(.dropzone) input[type="radio"] + label::after {
    display: none;
}

/* Forms in Modals */
.modal .modal-content form select {
    width: 100%;
}






