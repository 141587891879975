.user-info {
    border-radius: 0 0 5px 5px;
    background: rgba(255, 255, 255, 0.075);
    padding: 24px;
}

.user-info .user-info-extended {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.user-info-extended .user-stats {
    padding: 8px;
}

.user-info-extended .user-stats h5 {
    margin: 0 0 8px 0;
}

.user-info-extended .user-avatar-container {
    padding: 6px;
    border-radius: 100%;
    border: solid 4px var(--primary-color);    
    margin-right: 16px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-info-extended .user-avatar-container img {
    height: 128px;
    width: 128px;
    border-radius: 100%;
}
