form input[type="submit"], button {
    padding: 8px 24px;
    background: var(--primary-color);
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #212121;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    text-transform: capitalize;
}

.minor-btn {
    padding: 8px 24px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    text-transform: capitalize;
    text-decoration: none;
    border: solid 0.5px #bdbdbd;
    display: block;
    width: fit-content;
}

.minor-btn:hover {
    color: #212121;
    background: #eee;
}

form input[type="submit"]:hover, form input[type="submit"]:focus, button:focus, button:hover {
    background: var(--primary-hover);
    border: solid 2px var(--primary-color);
}

.secondary-btn {
    margin: 16px auto;
    display: block;
    width: fit-content;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 5px;
}

.secondary-btn::before {
    font-family: 'Material Icons';
    color: var(--primary-color);
    margin-right: 8px;
    font-size: 24px;
    position: relative;
    top: 4px;
    transition: all 0.2s ease-out;
}

.secondary-btn:hover {
    background: var(--primary-hover);
    color: #212121;
}

.secondary-btn:hover::before {
    color: #212121;
}

.secondary-btn.left {
    margin-left: 0;
}

a.image-upload::before {
    content: 'cloud_upload';
}

a.edit-profile::before {
    content: 'account_box';
}

a.album-create::before, a.return-images::before {
    content: 'photo_library';
}

a.return-home::before {
    content: 'home';
}

a.album-view::before {
    content: 'photo_library';
}

a.delete-cancel::before {
    content: 'undo';
}

a.edit-profile.secondary-btn {
    margin-left: 0;
    padding-left: 0;
}

a.paginate-btn {
    white-space: nowrap;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 20px;
    font-family: var(--font-family);
    text-decoration: none;
    color: #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
}

a.paginate-btn:hover {
    color: #fff;
}

a.paginate-btn.paginate-prev::before, a.paginate-btn.paginate-next::after {
    font-family: 'Material Icons';
    font-size: 24px;
    display: block;
    color: inherit;
    margin: 0 8px;
}

a.paginate-btn.paginate-prev::before {
    content: 'keyboard_arrow_left';
}

a.paginate-btn.paginate-next::after {
    content: 'keyboard_arrow_right';
}
