/* --- Scrollbar Styles --- */

/* width */
div.modal-content::-webkit-scrollbar, .modal-content span.stat-detail::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
div.modal-content::-webkit-scrollbar-track, .modal-content span.stat-detail::-webkit-scrollbar-track {
    background: var(--dark-background);
}
  
  /* Handle */
div.modal-content::-webkit-scrollbar-thumb, .modal-content span.stat-detail::-webkit-scrollbar-thumb {
    background: #535353;
    border-radius: 5px;
}