h2, h3, h4, h5, h6, p, a {
    font-family: var(--font-family);
    font-weight: 300;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

form input, form textarea, form select {
    font-family: var(--font-family);
    border-radius: 5px;
    font-size: 16px;
    color: #000;
}

form label {
    font-family: var(--font-family);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    text-transform: capitalize;
}

h1 {
    font-size: 32px;
    font-family: monospace;
    color: #fff;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    font-size: 16px;
}

ul {
    padding: 0;
}

ul li {
    list-style: none;
    height: auto;
    padding: 8px;

}

ul li.nested-li {
    display: flex;
    justify-content: center;
    align-items: center;
}

ul li.nested-li::before {
    content: 'subdirectory_arrow_right';
    font-family: 'Material Icons';
    font-size: 16px;
    color: #bdbdbd;
    display: inline-block;
    margin-right: 4px;
}

ul.message-notice li {
    font-family: var(--font-family);
    color: var(--primary-color);
    font-size: 14px;
    text-align: right;
    padding: 8px;
    background: var(--dark-background);
    border-radius: 5px;
}

a:not(header nav a) {
    color: var(--primary-color);
}

header nav a {
    font-weight: 600;
    font-size: 18px;
}

.copy-to-clipboard::before {
    color: inherit;
    font-size: inherit;
    font-family: 'Material Icons';
    content: 'content_copy';
    display: inline-block;
    margin-right: 8px;
}

.copy-to-clipboard {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    width: fit-content;
}

.copy-to-clipboard:not(span.stat-detail):hover::after {
    content: 'Copy text to clipboard.';
    display: block;
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    background: rgba(0,0,0,0.85);
    color: #eee;
    width: fit-content;
    white-space: nowrap;
    padding: 4px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    z-index: 50000;
}

p span.stat-detail {
    max-width: 100%;
    width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.primary-text {
    color: var(--primary-color);
}

.helper {
    background: transparent;
    border: solid 1px rgba(255,255,255,0.25);
    border-radius: 5px;
    padding: 8px 16px;
    margin: 8px auto;
    width: fit-content;
    display: flex;
    align-items: center;
    color: rgba(255,255,255,0.5);
}

.helper::before {
    font-family: 'Material Icons';
    color: var(--primary-color);
    font-size: 24px;
    margin-right: 8px;
    display: block;
}

.helper.click::before {
    content: 'ads_click';
}