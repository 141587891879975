form#formImageSettings {
    width: 100%;
    padding: 0;
    margin: 0;
}

form#formImageSettings p:empty, form#formImageSettings br {
    display: none;
}

form#formImageSettings p[data-column]::before {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 16px;
    content: attr(data-column);
    background: var(--primary-color);
    font-size: 24px;
    color: #fff;
    height: 36px;
    width: 36px;
    font-family: 'Material Icons';
}

p.share-link::before {
    font-family: 'Material Icons';
    color: var(--primary-color);
    font-size: 20px;
    margin-right: 8px;
    position: relative;
    top: 4px;
}

p.share-link.bb-code::before {
    content: 'data_array';
}

p.share-link.html-code::before {
    content: 'code';
}

p.share-link.url-code::before {
    content: 'link';
}

div.img-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

div.img-actions * {
    margin: 8px;
    margin-bottom: 24px;
}
